import .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*-----------------------------------------------MY CSS----------------------------------------------------*/

.container {
  display: flex;
  height: 100vh; /* full height of the viewport */
}

.left-half {
  flex: 1; /* This will take half the container's width */
  background: url("./images/mic_bg.jpg") center/cover no-repeat; /* Adjust the path to your image */
}

.right-half {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*-------LOGIN MODAL----------*/
.modal {
  width: 50%; /* or whatever width you prefer */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
}

.logo {
  width: 50px; /* Adjust as needed */
  height: 50px;
  margin-bottom: 20px;
}

/*-----HOME-----*/
.home-container {
  display: flex;
  flex-direction: column;
}

.content-section {
  display: flex;
}

.search-bar {
  display: flex;
  align-items: center;
  padding: 1rem;
  max-height: 2rem;
}

.logo {
  margin-right: 10%; /* Adjust as needed */
}

.search-input {
  flex-grow: 1; /* Helps the search input to take available space */
  height: 2rem;
  max-width: 30rem;
  border-radius: 15px;
}

.account-link {
  margin-left: auto; /* Pushes the account link to the right */
  padding-left: 2rem;
}

.nav-bar {
  min-width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.upload-btn {
  color: plum;
  padding: 10px 20px;
  font-size: 1rem; /* Adjust based on preference */
  border: 1px solid plum;
  border-radius: 5px;
  background-color: rgb(241, 239, 239);
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-btn:hover {
  background-color: plum;
  color: white;
}

.nav-link {
  color: blue;
  display: block;
  padding: 10px 0;
  text-decoration: none;
  transition: background-color 0.3s;
}

.storage-indicator {
  height: 20px; /* Adjust as needed */
  background-color: #e0e0e0;
}

.filled-storage {
  width: 50%; /* This should be dynamic based on actual storage use */
  height: 100%;
  background-color: #4caf50;
  border-radius: 20px;
}

.dashboard {
  flex-grow: 1; /* Helps the dashboard to take available space */
  padding: 1rem;
}

.header-container {
  border-bottom: 3px solid #000;
}
.nav-container {
  border-right: 3px solid #000;
  width: 200px;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  left: 0;
  top: 0;
  overflow-y: auto;
}

.storage-text {
  padding: 0px;
}

/*Sign-Up Modal*/
.signup-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px; /* Or whatever size you want */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.close-btn {
  color: red;
  font-size: 20px;
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
}

/***** SIGN UP MODAL*****/
.signup-modal-overlay.hidden {
  display: none;
}
/****/
